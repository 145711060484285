<template>
    <div class="h-screen bg-blue-300 flex align-items-center justify-content-around">
        <div v-show="loadingIndex > 0" class="w-full">
            <div class="flex align-content-around w-full">
                <ProgressSpinner/>
            </div>
        </div>

        <div class="w-full" style="max-width: 32rem;" v-show="loadingIndex === 0">
            <div class="flex flex-wrap align-items-stretch">
                <div class="w-full p-6 pb-3 surface-card bg-white border-1 surface-border">
                    <div class="flex flex-wrap align-items-center h-full">
                        <div class="w-full">
                            <img src="./../assets/img/logo-blue.png"
                                 style="width: 100%;"
                                 class="px-8 py-4 mb-4"
                                 alt=""/>
                            <router-view class="w-full"></router-view>

                            <ul class="list-none block text-center mx-0 px-0 w-full">
                                <li class="m-3 inline-block"
                                    v-for="(name, routePath) in links"
                                    v-bind:key="routePath"
                                >
                                    <router-link
                                        v-if="routePath !== $route.path"
                                        :to="routePath"
                                        class="text-primary no-underline uppercase"
                                    >
                                        {{ name }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProgressSpinner from 'primevue/progressspinner';

    export default {
        components: {
            ProgressSpinner
        },
        computed: {
            loadingIndex() {
                return this.$store.getters.getLoadingIndex;
            }
        },
        mounted() {
            if (this.$route.name === 'Security') {
                this.$router.push('/dashboard');
            }
        },
        data() {
            return {
                links: {
                    '/login': 'Login',
                    '/password-forget': 'Passwort vergessen',
                }
            }
        },
    }
</script>
